import React, { useEffect, useState } from "react";
import { Button } from "@slid/slid-ips";
import { trackEvent } from "src/utils/eventTracking";
import { deviceType } from "react-device-detect";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { useMediaQuery } from "react-responsive";
import { useLoadableVariation } from "@hackler/react-sdk";

interface SignUpCTAProps {
  size: "medium" | "large" | "maxWidthLarge";
  origin: "home section" | "ending section" | "nav";
}

const SignUpCTAButton = ({ origin, size }: SignUpCTAProps) => {
  const router = useRouter();
  const { t } = useTranslation("Landing");
  const [isDesktop, setIsDesktop] = useState(true);
  const mobile = useMediaQuery({ query: "(max-width:1224px) and ( min-width:360px)" });
  const smallMobile = useMediaQuery({ query: "(max-width:359px)" });
  const { isLoading, variation } = useLoadableVariation(13);
  const [CTAButtonText, setCTAButtonText] = useState<string>("LandingPageFreeTrialCTA");

  useEffect(() => {
    // to use useMediaQuery in nextJS properly
    if (mobile || smallMobile) {
      setIsDesktop(false);
    } else {
      setIsDesktop(true);
    }
  }, [mobile, smallMobile]);

  useEffect(() => {
    // A(current case) : Start free trial & B(rollback case) : Get Started
    if (variation === "A") setCTAButtonText("LandingPageFreeTrialCTA");
    else if (variation === "B") setCTAButtonText("LandingPageCTA");
  }, [isLoading]);

  if (isLoading) return null;

  return (
    <Button
      size={size}
      color={`--blue8`}
      appearance={`primary`}
      text={t(CTAButtonText)}
      marginTop={origin === "nav" ? "" : !isDesktop ? "32px" : "60px"}
      callback={() => {
        trackEvent({
          eventType: "Click GET STARTED button",
          eventProperties: {
            origin,
            deviceType: deviceType,
          },
        });
        router.push(`/sign-up`);
      }}
    />
  );
};

export default SignUpCTAButton;
