import { Icon, Menu } from "@slid/slid-ips";
import React, { useEffect, useState } from "react";

import { setLang as setGlobalLang } from "@/redux/modules/slidGlobalSlice";

import router from "next/router";
import { useAppDispatch, useAppSelector } from "src/hooks";

interface LanguageMenuProps {
  color: "--gray15" | "--gray3" | "--gray9";
}

const LanguageMenu = ({ color }: LanguageMenuProps) => {
  const dispatch = useAppDispatch();
  const lang = useAppSelector((state) => state.slidGlobal.lang);
  const [languageValue, setLanguageValue] = useState<string>(lang);

  const changeLanguage = (lang: string) => {
    const { pathname, query, asPath } = router;
    router.push({ pathname, query }, asPath, { locale: lang });
  };

  const LANGUAGE_LIST = [
    {
      text: "English",
      callback: async () => {
        changeLanguage("en");
        setLanguageValue("English");
        await dispatch(setGlobalLang("en"));
      },
    },
    {
      text: "한국어",
      callback: async () => {
        changeLanguage("ko");
        setLanguageValue("한국어");
        await dispatch(setGlobalLang("ko"));
      },
    },
  ];

  useEffect(() => {
    setLanguageValue(lang === "ko" ? "한국어" : "English");
  }, [lang]);

  return <Menu icon={<Icon icon={`language24`} color={color} />} itemList={LANGUAGE_LIST} menuValue={languageValue} color={color} />;
};

export default LanguageMenu;
