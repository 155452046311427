import React, { useEffect, useRef, useState } from "react";
import { Button, ColorTypes, Icon, Link, Sticker } from "@slid/slid-ips";
import styled from "@emotion/styled";
import Lottie from "react-lottie";
import animationData from "src/components/animations/loading_animation.json";
import { motion, Variants } from "framer-motion";

import Head from "next/head";
import Image from "next/image";

import { Trans, useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useMediaQuery } from "react-responsive";
import { useRouter } from "next/router";
import { useElementApppearsOnScreen, useCallFirstTrackEvent } from "src/utils/customHook/customHook";

import LandingNav from "src/components/nav/LandingNav";
import LandingFooter from "src/components/footer/LandingFooter";
import DownloadToggleButton from "src/components/buttons/DownloadToggleButton";
import { LinkArrowIcon } from "src/components/icons/LinkArrowIcon";
import { ShiningIcon } from "src/components/icons/ShiningIcon";
import { StarIcon } from "src/components/icons/StarIcon";

import env from "@/config/env";
import { trackEvent } from "src/utils/eventTracking";
import { deviceType } from "react-device-detect";
import { useIsAuthenticated } from "src/utils/customHook/useIsAuthenticated";
import { useAppSelector, useRemoteConfig } from "src/hooks";
import SignUpCTAButton from "src/components/buttons/SignUpCTAButton";

const SLID_WEB_APP_URL = env.end_point_url.slid_web_app;
const SLID_FEATURE_VIDEO = [
  "https://slid-public-assets.s3.us-west-1.amazonaws.com/Design/landing_demo_video1_en.webm",
  "https://slid-public-assets.s3.us-west-1.amazonaws.com/Design/landing_demo_video2_en.webm",
  "https://slid-public-assets.s3.us-west-1.amazonaws.com/Design/landing_demo_video3_en.webm",
  "https://slid-public-assets.s3.us-west-1.amazonaws.com/Design/landing_demo_video4_en.webm",
];
const SLID_FEATURE_VIDEO_MP4 = [
  "https://slid-public-assets.s3.us-west-1.amazonaws.com/Design/landing_demo_video1_en.mp4",
  "https://slid-public-assets.s3.us-west-1.amazonaws.com/Design/landing_demo_video2_en.mp4",
  "https://slid-public-assets.s3.us-west-1.amazonaws.com/Design/landing_demo_video3_en.mp4",
  "https://slid-public-assets.s3.us-west-1.amazonaws.com/Design/landing_demo_video4_en.mp4",
];
const SLID_DOWNLOAD_CDN_URL = env.links.slid_download_cdn_url;

type testimonialProps = { content: string; imgSrc: string; name: string; jobTitle: string };

type sharedNoteProps = {
  tag: string;
  title: string;
  link: string;
  imgSrc: string;
};

const Home = () => {
  const router = useRouter();
  const { t } = useTranslation("Landing");
  const { lang } = useAppSelector((state) => state.slidGlobal);
  const mobile = useMediaQuery({ query: "(max-width:1224px) and ( min-width:360px)" });
  const smallMobile = useMediaQuery({ query: "(max-width:359px)" });

  const featureRef = useRef<HTMLElement | null>(null);
  const downloadRef = useRef<HTMLDivElement | null>(null);
  const productFeatureRefs = useRef([React.createRef<HTMLDivElement>(), React.createRef<HTMLDivElement>(), React.createRef<HTMLDivElement>(), React.createRef<HTMLDivElement>()]);

  const isFirstFeatureAppeared = useElementApppearsOnScreen(productFeatureRefs.current[0], false);
  const isSecondFeatureAppeared = useElementApppearsOnScreen(productFeatureRefs.current[1], false);
  const isThirdFeatureAppeared = useElementApppearsOnScreen(productFeatureRefs.current[2], false);
  const isFourthFeatureAppeared = useElementApppearsOnScreen(productFeatureRefs.current[3], false);

  const [downloadType, setDownloadType] = useState("MobileApp");
  const [productFeatureVideo, setProductFeatureVideo] = useState(0);
  const [isVideoChanged, setIsVideoChanged] = useState(false);
  const [isFeatureVideoLoaded, setIsFeatureVideoLoaded] = useState(false);
  const [isDesktop, setIsDesktop] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [isSmallMobile, setIsSmallMobile] = useState(false);
  const [stableVersions, setStableVersions] = useState<any>({});

  const isAuthenticated = useIsAuthenticated();

  const { remoteConfigValue, isLoading: isRemoteConfigFeching } = useRemoteConfig("versions");

  const version = stableVersions && stableVersions["slid_desktop_mac_version"] ? stableVersions["slid_desktop_mac_version"] : "3.1.2";

  useEffect(() => {
    if (isAuthenticated) router.push(`${SLID_WEB_APP_URL}`);
  }, []);

  useEffect(() => {
    if (isRemoteConfigFeching) return;
    setStableVersions(remoteConfigValue);
  }, [isRemoteConfigFeching]);

  useEffect(() => {
    if (isFirstFeatureAppeared) {
      setProductFeatureVideo(0);
    } else if (isSecondFeatureAppeared && !isFirstFeatureAppeared) {
      setProductFeatureVideo(1);
    } else if (isThirdFeatureAppeared && !isSecondFeatureAppeared) {
      setProductFeatureVideo(2);
    } else if (isFourthFeatureAppeared && !isThirdFeatureAppeared) {
      setProductFeatureVideo(3);
    } else {
      setProductFeatureVideo(0);
    }
  }, [isFirstFeatureAppeared, isSecondFeatureAppeared, isThirdFeatureAppeared, isFourthFeatureAppeared]);

  useEffect(() => {
    // for product feature video interaction
    setIsVideoChanged(true);
    setTimeout(() => {
      setIsVideoChanged(false);
    }, 1000);
  }, [productFeatureVideo]);

  useEffect(() => {
    // to use useMediaQuery in nextJS properly
    if (mobile || smallMobile) {
      setIsDesktop(false);
    } else {
      setIsDesktop(true);
    }

    setIsMobile(mobile);
    setIsSmallMobile(smallMobile);
  }, [mobile, smallMobile]);

  useCallFirstTrackEvent(() => {
    trackEvent({
      eventType: "Visit landing page",
      eventProperties: {
        deviceType: deviceType,
      },
    });
  });

  const SHARED_NOTE_LIST = t<string, sharedNoteProps[]>("SharedNoteData", { returnObjects: true });
  const TESTIMONIAL_LIST = t<string, testimonialProps[]>("TestimonialData", { returnObjects: true });
  const DOWNLOAD_OPTION_LIST = [
    {
      type: "WebExtension",
      list: [
        { icon: "Chrome", label: t("Chrome", { ns: "Landing" }), link: "https://chrome.google.com/webstore/detail/slid-youtube-screenshot-n/cgajiilhmpfemmdihjnodpibaffakjhj" },
        { icon: "Edge", label: t("Edge", { ns: "Landing" }), link: "https://microsoftedge.microsoft.com/addons/detail/slid-youtube-screenshot/npmcieaemkljlonpfggajgdmlcfcinoe" },
        { icon: "Whale", label: t("Whale", { ns: "Landing" }), link: "https://store.whale.naver.com/detail/mfoampfdgpphibplchlphhfgelagjpep" },
      ],
    },
    {
      type: "DesktopApp",
      list: [
        { icon: "Windows", label: t("Windows", { ns: "Landing" }), link: `${SLID_DOWNLOAD_CDN_URL}/Slid+Setup+${version}.exe` },
        { icon: "Mac", label: t("Mac", { ns: "Landing" }), link: `${SLID_DOWNLOAD_CDN_URL}/Slid-darwin-x64-${version}.zip` },
      ],
    },
    {
      type: "MobileApp",
      list: [
        { icon: "Android", label: t("Android", { ns: "Landing" }), link: "https://play.google.com/store/apps/details?id=cc.slid" },
        { icon: "iOS", label: t("iOS", { ns: "Landing" }), link: "https://apps.apple.com/kr/app/slid-app/id1631068663?l=en" },
      ],
    },
  ];

  const defaultLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    renderer: "svg",
  };

  const executeScrollTo = (ref: any) => {
    if (ref) ref.scrollIntoView();
  };

  const onToggleDownloadButton = (type: string) => {
    setDownloadType(type);
  };

  const getSharedNotes = () => {
    const arr: number[] = [];

    for (let i = 0; i < SHARED_NOTE_LIST.length; i += 5) {
      arr.push(i);
    }

    return arr.map((group, i) => {
      return (
        <SharedNoteInnerContainer key={group + i}>
          {[0, 1].map((item, i) => (
            <SharedNotesAnimationSpan key={item + i}>
              {SHARED_NOTE_LIST.slice(group, group + 5).map((item: sharedNoteProps) => {
                return (
                  <SharedNoteCard
                    key={item.title}
                    onClick={() => {
                      trackEvent({
                        eventType: "Click SHARED NOTE card",
                        eventProperties: {
                          deviceType: deviceType,
                          link: item.link,
                        },
                      });
                      window.open(item.link);
                    }}
                  >
                    <NoteImageContainer>
                      <NoteImage src={item.imgSrc} alt={item.title} />
                    </NoteImageContainer>
                    <NoteInfoWrapper>
                      <Sticker color={getStickerColor(item.tag)} text={item.tag} />
                      <NoteTitleText>{item.title}</NoteTitleText>
                    </NoteInfoWrapper>
                  </SharedNoteCard>
                );
              })}
            </SharedNotesAnimationSpan>
          ))}
        </SharedNoteInnerContainer>
      );
    });
  };

  const getDownloadOptionsForMobile = () => {
    const downloadButtonArr: JSX.Element[] = [];
    DOWNLOAD_OPTION_LIST.forEach((listItem) => {
      if (listItem.type === downloadType) {
        listItem.list.map((item: any) => {
          downloadButtonArr.push(getDownloadButton(item, listItem.type));
        });
      }
    });

    return downloadButtonArr;
  };

  const getDownloadButton = (item: { icon: string; label: string; link: string }, type: string) => {
    return (
      <DownloadLinkButton
        key={item.label}
        onClick={() => {
          trackEvent({
            eventType: "Click DOWNLOAD button",
            eventProperties: {
              origin: item.icon,
              deviceType: deviceType,
            },
          });
          window.open(item.link);
        }}
      >
        <DownloadButtonIcon src={`/assets/icons/Icon${item.icon}.svg`} alt={`${item.label}`} />
        <DownloadButtonText>{item.label}</DownloadButtonText>
        {type === "DesktopApp" ? <Icon icon={`import20`} color={`--gray9`} /> : <LinkArrowIcon width={20} height={21} />}
      </DownloadLinkButton>
    );
  };

  const getStickerColor = (type: string) => {
    switch (type) {
      case "Business":
      case "Insurance":
        return ColorTypes.Red;
      case "Language":
      case "Religion":
        return ColorTypes.Orange;
      case "Biology":
      case "Psychology":
      case "Physics":
        return ColorTypes.Green;
      case "Programming":
        return ColorTypes.Purple;
      default:
        return ColorTypes.Blue;
    }
  };

  const PageMetatags = () => {
    return (
      <Head>
        <title>{t("Meta.title")}</title>
        <link rel="icon" href="/favicon.ico" />
        <meta name="description" lang="ko" content={t("Meta.nameDescription")} />
        <meta name="keywords" content={t("Meta.keywords")} />
        <meta property="og:url" content="https://slid.cc" />
        <link rel="alternate" href="https://slid.cc/en" hrefLang="en" />
        <link rel="alternate" href="https://slid.cc/ko" hrefLang="ko" />
        <meta property="og:image" content="/og/cover.png" />

        <meta property="og:title" content={t("Meta.ogTitle")} />
        <meta property="description" content={t("Meta.propertyDescription")} />
        <meta property="og:description" content={t("Meta.ogDescription")} />
        <link rel="canonical" href={t("Meta.canonical")} />
      </Head>
    );
  };

  const originalSectionTitle = () => {
    return (
      <>
        <SectionTitleText>
          <Trans i18nKey="HomeSectionTitle" components={{ gradient: <CenteredSectionTitleGradientText /> }}>
            {t("HomeSectionTitle")}
          </Trans>
        </SectionTitleText>
        <SectionDescriptionText>{t(isDesktop ? "HomeSectionDesc" : "MobileHomeSectionDesc")}</SectionDescriptionText>
        <SignUpCTAButton origin="home section" size={!isDesktop ? "medium" : "large"} />
        <HomeSectionButtonDesc>
          <Trans>{t("CTADescription")}</Trans>
        </HomeSectionButtonDesc>
      </>
    );
  };

  return (
    <HomeContainer>
      <LandingNav
        executeScrollToFeature={() => executeScrollTo(featureRef.current)}
        executeScrollToDownload={() => executeScrollTo(downloadRef.current)}
        isSmallMobile={isSmallMobile}
        isMobile={isMobile}
        isDesktop={isDesktop}
      />
      <PageMetatags />
      <HomeSectionContainer>
        <HomeBackgroundElement src="/assets/design/slid_landing_background_elem.png" variants={backgroundVariants} initial="offscreen" whileInView="onscreen" viewport={{ once: true }} />
        {[0, 1, 2].map((index) => {
          return <BackGroundCircleElement variants={circleElementVariants} initial="offscreen" whileInView="onscreen" viewport={{ once: true }} key={`${index}-home`} />;
        })}
        <SectionContentWrapper>
          <SectionTopContentWrapper variants={contentVariants} initial="offscreen" whileInView="onscreen" viewport={{ once: true }}>
            {originalSectionTitle()}
          </SectionTopContentWrapper>
          <HomeSectionImageContainer variants={contentVariants} initial="offscreen" whileInView="onscreen" viewport={{ once: true }}>
            <ImageWrapper>
              <Image src={t("HomeSectionImageText")} alt="slid" layout="fill" />
            </ImageWrapper>
          </HomeSectionImageContainer>
          <FakeDivTagForScroll ref={downloadRef} id={"download"} />
          {isDesktop ? (
            <DownloadContainer variants={contentVariants} initial="offscreen" whileInView="onscreen" viewport={{ once: true }}>
              {DOWNLOAD_OPTION_LIST.map((option) => {
                return (
                  <DownloadBox key={option.type}>
                    <DownloadTitle>{t(option.type)}</DownloadTitle>
                    <DownloadDesc>{t(`${option.type}Desc`)}</DownloadDesc>
                    <DownloadButtonWrapper>
                      {option.list.map((item) => {
                        return getDownloadButton(item, option.type);
                      })}
                    </DownloadButtonWrapper>
                  </DownloadBox>
                );
              })}
            </DownloadContainer>
          ) : (
            <MobileDownloadSectionContainer variants={contentVariants} initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.8 }} ref={downloadRef} id={"download"}>
              <DownloadToggleButton onToggle={onToggleDownloadButton} downloadType={downloadType} />
              <DownloadContainer>
                <DownloadBox>
                  <DownloadTitle>{t(downloadType)}</DownloadTitle>
                  <DownloadDesc>{t(`${downloadType}Desc`)}</DownloadDesc>
                  <DownloadButtonWrapper>{getDownloadOptionsForMobile()}</DownloadButtonWrapper>
                </DownloadBox>
              </DownloadContainer>
            </MobileDownloadSectionContainer>
          )}
        </SectionContentWrapper>
      </HomeSectionContainer>
      <FeatureSectionContainer ref={featureRef} id={"feature"}>
        <FeatureSectionContentWrapper>
          <FeatureTitleWrapper variants={contentVariants} initial="offscreen" whileInView="onscreen" viewport={{ once: true }}>
            <FeatureSectionTitle>
              <Trans i18nKey="FeatureSectionTitle" components={{ gradient: <SectionTitleGradientText /> }}>
                {t("FeatureSectionTitle")}
              </Trans>
            </FeatureSectionTitle>
            {isDesktop && (
              <Button
                text={t("TryDemoFreeCTA")}
                appearance={"line"}
                size={!isDesktop ? `medium` : `large`}
                callback={() => {
                  trackEvent({
                    eventType: "Click TRY DEMO NOW button",
                    eventProperties: {
                      origin: "feature section",
                    },
                  });
                  router.push(`${SLID_WEB_APP_URL}/demo`);
                }}
                icon={!isDesktop ? undefined : <ShiningIcon width={24} height={24} />}
                marginTop={!isDesktop ? "20px" : "32px"}
              />
            )}
          </FeatureTitleWrapper>
          <FeatureContainer>
            <FeatureListContainer>
              {[1, 2, 3, 4].map((item, index) => {
                return (
                  <FeatureItemContainer key={index}>
                    <LineElement>
                      <Dot ref={productFeatureRefs.current[index]} />
                      <Line />
                    </LineElement>
                    <FeatureListItem variants={contentVariants} initial="offscreen" whileInView="onscreen" viewport={{ once: true }}>
                      <FeatureTitle>{t(`Feature${item}Title`)}</FeatureTitle>
                      <FeatureDescription>{t(`Feature${item}Desc`)}</FeatureDescription>
                    </FeatureListItem>
                    {!isDesktop && (
                      <ProductDemoContainer variants={contentVariants} initial="offscreen" whileInView="onscreen" viewport={{ once: true }}>
                        <ProductDemo>
                          <ProductFeatureVideo autoPlay loop muted isVideoVisible={true} isVideoChanged={false} playsInline controls={false} preload="yes">
                            <source src={SLID_FEATURE_VIDEO[index]} type="video/webm"></source>
                            <source src={SLID_FEATURE_VIDEO_MP4[index]} type="video/mp4"></source>
                          </ProductFeatureVideo>
                        </ProductDemo>
                      </ProductDemoContainer>
                    )}
                  </FeatureItemContainer>
                );
              })}
            </FeatureListContainer>
            {isDesktop && (
              <ProductDemoContainer variants={contentVariants} initial="offscreen" whileInView="onscreen" viewport={{ once: true }}>
                <ProductDemo>
                  {!isFeatureVideoLoaded && (
                    <LottieContainer>
                      <Lottie options={defaultLottieOptions} height={80} width={80} />
                    </LottieContainer>
                  )}
                  {SLID_FEATURE_VIDEO.map((video, index) => {
                    return (
                      <ProductFeatureVideo
                        autoPlay
                        loop
                        muted
                        src={video}
                        preload={"auto"}
                        onLoadedData={() => {
                          setIsFeatureVideoLoaded(true);
                        }}
                        key={video}
                        isVideoVisible={index === productFeatureVideo && !isVideoChanged}
                        isVideoChanged={isVideoChanged}
                        playsInline
                      />
                    );
                  })}
                </ProductDemo>
                <FeatureBackgroundElement src="/assets/design/slid_landing_background_elem.png" alt="" />
              </ProductDemoContainer>
            )}
          </FeatureContainer>
        </FeatureSectionContentWrapper>
      </FeatureSectionContainer>
      <SharedNoteSectionContainer>
        <SharedNoteBackgroundElement src="/assets/design/slid_landing_background_elem.png" alt="" variants={backgroundVariants} initial="offscreen" whileInView="onscreen" viewport={{ once: true }} />
        <SectionTitleText variants={contentVariants} initial="offscreen" whileInView="onscreen" viewport={{ once: true }}>
          {isDesktop ? (
            <Trans i18nKey="SharedNoteSectionTitle" components={{ gradient: <SectionTitleGradientText /> }}>
              {t("SharedNoteSectionTitle")}
            </Trans>
          ) : (
            <Trans i18nKey="MobileSharedNoteSectionTitle" components={{ gradient: <CenteredSectionTitleGradientText /> }}>
              {t("MobileSharedNoteSectionTitle")}
            </Trans>
          )}
        </SectionTitleText>
        <SectionDescriptionText variants={contentVariants} initial="offscreen" whileInView="onscreen" viewport={{ once: true }}>
          {t("SharedNoteSectionDesc")}
        </SectionDescriptionText>
        <SharedNoteContainer variants={contentVariants} initial="offscreen" whileInView="onscreen" viewport={{ once: true }}>
          {getSharedNotes()}
        </SharedNoteContainer>
        <GradientElem />
        <GradientElem />
      </SharedNoteSectionContainer>
      <TestimonialSectionContainer>
        <SectionTitleText variants={contentVariants} initial="offscreen" whileInView="onscreen" viewport={{ once: true }}>
          <Trans i18nKey="TestimonialSectionTitle" components={{ gradient: <SectionTitleGradientText /> }}>
            {t("TestimonialSectionTitle")}
          </Trans>
        </SectionTitleText>
        <SectionDescriptionText variants={contentVariants} initial="offscreen" whileInView="onscreen" viewport={{ once: true }}>
          {t("TestimonialSectionDesc")}
        </SectionDescriptionText>
        <TestimonialContainer variants={contentVariants} initial="offscreen" whileInView="onscreen" viewport={{ once: true }}>
          <TestimonialInnerContainer>
            {[0, 1].map((item, index) => {
              return (
                <TestimonialAnimationSpan key={item + index}>
                  {TESTIMONIAL_LIST.map((item: testimonialProps) => {
                    return (
                      <TestimonialCard key={item.content}>
                        <TestimonialContent>{`"${item.content}"`}</TestimonialContent>
                        <TestimonialWriterInfo>
                          <WriterAvatar>
                            <Image src={`${item.imgSrc}`} alt={`profile`} width={32} height={32} />
                          </WriterAvatar>
                          <WriterNameWrapper>
                            <WriterName>{`${item.name}`}</WriterName>
                            <ScoreWrapper>
                              <StarIcon width={16} height={16} />
                              <StarIcon width={16} height={16} />
                              <StarIcon width={16} height={16} />
                              <StarIcon width={16} height={16} />
                              <StarIcon width={16} height={16} />
                            </ScoreWrapper>
                          </WriterNameWrapper>
                        </TestimonialWriterInfo>
                      </TestimonialCard>
                    );
                  })}
                </TestimonialAnimationSpan>
              );
            })}
          </TestimonialInnerContainer>
        </TestimonialContainer>
        {isDesktop && (
          <LinkWrapper variants={contentVariants} initial="offscreen" whileInView="onscreen" viewport={{ once: true }}>
            <Link
              linkText={t("TestimonialLinkText")}
              href={t("TestimonialLinkHref")}
              infoText={t("TestimonialLinkAbout")}
              newTab={true}
              onClick={() => {
                trackEvent({
                  eventType: "Click SEE MORE REVIEWS link",
                  eventProperties: {
                    deviceType: deviceType,
                  },
                });
              }}
            />
          </LinkWrapper>
        )}
      </TestimonialSectionContainer>
      <EndingSectionContainer>
        {[0, 1, 2].map((index) => {
          return <BackGroundCircleElement variants={circleElementVariants} initial="offscreen" whileInView="onscreen" viewport={{ once: true }} key={`${index}-home`} />;
        })}
        <EndingSectionTitleGradientText variants={contentVariants} initial="offscreen" whileInView="onscreen" viewport={{ once: true }}>
          {t("EndingSectionTitle")}
        </EndingSectionTitleGradientText>
        <ButtonWrapper variants={contentVariants} initial="offscreen" whileInView="onscreen" viewport={{ once: true }}>
          <SignUpCTAButton origin="ending section" size={!isDesktop ? "medium" : "large"} />
          {isDesktop && (
            <Button
              text={t("TryDemoFreeCTA")}
              appearance={"line"}
              size={!isDesktop ? `medium` : `large`}
              callback={() => {
                trackEvent({
                  eventType: "Click TRY DEMO NOW button",
                  eventProperties: {
                    origin: "ending section",
                  },
                });
                router.push(`${SLID_WEB_APP_URL}/demo`);
              }}
              marginTop={!isDesktop ? "32px" : "60px"}
            />
          )}
        </ButtonWrapper>
      </EndingSectionContainer>
      <LandingFooter
        executeScrollToFeature={() => executeScrollTo(featureRef.current)}
        executeScrollToDownload={() => {
          executeScrollTo(downloadRef.current);
          setDownloadType("MobileApp");
        }}
        isDesktop={isDesktop}
      />
    </HomeContainer>
  );
};

export async function getStaticProps({ locale }: any) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["Landing", "Footer", "Navigation"])),
    },
  };
}

const contentVariants: Variants = {
  onscreen: { y: 0, opacity: 1, transition: { duration: 1.5 } },
  offscreen: { y: 100, opacity: 0 },
};

const circleElementVariants: Variants = {
  onscreen: { y: 0, opacity: 1, transition: { duration: 5, type: "spring", bounce: 0.7 } },
  offscreen: { y: -100, opacity: 0 },
};

const backgroundVariants: Variants = {
  onscreen: { y: 0, opacity: 1, transition: { duration: 1.5 } },
  offscreen: { y: 100, opacity: 0 },
};

const BackgroundElement = styled(motion.img)`
  position: absolute;
`;

const BackGroundCircleElement = styled(motion.div)`
  position: absolute;
  width: 31px;
  height: 31px;
  border-radius: 100%;
  z-index: -1;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.05);

  &:nth-of-type(1) {
    left: 5%;
    top: 20vh;
    background: linear-gradient(180deg, rgba(68, 133, 255, 0.8) 0%, #377dff 100%);
    opacity: 0.6;
  }

  &:nth-of-type(2) {
    right: 10%;
    top: 50vh;
    background: linear-gradient(180deg, rgba(68, 133, 255, 0.8) 0%, #377dff 100%);
  }

  &:nth-of-type(3) {
    right: -1%;
    top: 10vh;
    background: linear-gradient(180deg, #ac8eff 0%, #6724f6 100%);
  }

  &:nth-of-type(4) {
    left: 15%;
    top: 5vh;
    background: linear-gradient(180deg, rgba(68, 188, 255, 0.8) 0%, #37b7ff 100%);
  }

  @media (max-width: 1224px) {
    opacity: 0.7 !important;
    width: 15.5px;
    height: 15.5px;

    &:nth-of-type(1) {
      left: 0%;
      top: 20vh;
      background: linear-gradient(180deg, rgba(68, 133, 255, 0.8) 0%, #377dff 100%);
    }

    &:nth-of-type(2) {
      right: 15%;
      top: 2vh;
      background: linear-gradient(180deg, rgba(68, 133, 255, 0.8) 0%, #377dff 100%);
    }

    &:nth-of-type(3) {
      right: -1%;
      top: 10vh;
      background: linear-gradient(180deg, #ac8eff 0%, #6724f6 100%);
    }

    &:nth-of-type(4) {
      left: 15%;
      top: 40vh;
      background: linear-gradient(180deg, rgba(68, 188, 255, 0.8) 0%, #37b7ff 100%);
    }
  }
`;

const SectionWrapper = styled(motion.section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100%;
  position: relative;
`;

const SectionContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (max-width: 1224px) and (min-width: 360px) {
    max-width: "580px";
  }

  @media (max-width: 359px) {
    max-width: "320px";
  }
`;

const SectionTopContentWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const SectionTitleText = styled(motion.h2)`
  margin: 0;
  font-weight: 700;
  font-size: 60px;
  line-height: 70px;
  color: var(--gray17);
  text-align: center;
  display: inline-block;
  z-index: 1;

  @media (max-width: 1224px) {
    font-size: 34px;
    line-height: 42px;
  }
`;

const SectionTitleGradientText = styled(motion.span)`
  margin: 0;
  font-weight: 700;
  font-size: 60px;
  line-height: 70px;
  color: var(--gray17);
  text-align: left;
  display: inline-block;

  @media (max-width: 1224px) {
    font-size: 34px;
    line-height: 42px;
  }
  background: linear-gradient(88.34deg, #4593fc 55.75%, #45c5fc 88.08%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
`;

const SectionDescriptionText = styled(motion.p)`
  margin: 0;
  margin-top: 24px;
  font-size: 20px;
  line-height: 28px;
  opacity: 0.6;
  color: var(--gray17);
  text-align: center;

  @media (max-width: 1224px) {
    margin-top: 12px;
    font-size: 15px;
    line-height: 23px;
  }
`;

const HomeContainer = styled(motion.div)`
  padding-top: 98px;
  font-family: "SlidLandingTextStyle", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HomeSectionContainer = styled(SectionWrapper)`
  padding: 97px 144px 80px 144px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1224px) {
    padding: 60px 20px 80px 20px;
  }
`;

const HomeBackgroundElement = styled(BackgroundElement)`
  top: -10vh;
  left: -10vw;
  z-index: -1;
`;

const HomeSectionButtonDesc = styled.span`
  display: inline-block;
  margin-top: 20px;
  font-size: 13px;
  line-height: 18px;
  color: var(--gray9);
`;

const HomeSectionImageContainer = styled(motion.div)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 117;
  max-width: 1152px;

  @media (max-width: 1224px) {
    max-width: 750px;
    padding: 0;
  }
`;

const ImageWrapper = styled.div`
  object-fit: contain;
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  padding-bottom: 65.79%;
  img {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

const FakeDivTagForScroll = styled.div``;

const DownloadContainer = styled(motion.div)`
  width: 100%;
  margin-top: 160px;
  display: flex;
  justify-content: center;
  gap: 24px;

  @media (max-width: 1224px) {
    margin-top: 8px;
  }
`;

const DownloadBox = styled.div`
  flex: 1;
  width: 100%;
  max-width: 368px;
  border-radius: 20px;
  background: var(--gray1);
  padding: 32px 0 32px 28px;
`;

const DownloadTitle = styled.h3`
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  color: var(--gray17);
`;

const DownloadDesc = styled.p`
  margin: 0;
  margin-top: 6px;
  font-size: 15px;
  line-height: 23px;
  color: var(--gray9);
`;

const DownloadButtonWrapper = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 1224px) {
    margin-top: 8px;
  }
`;

const DownloadLinkButton = styled.div`
  display: flex;
  align-items: flex-end;
  flex: 1 1 40%;
  margin-top: 20px;
  cursor: pointer;
`;

const DownloadButtonIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 12px;
`;

const DownloadButtonText = styled.span`
  font-size: 15px;
  line-height: 23px;
  color: var(--gray17);
`;

const MobileDownloadSectionContainer = styled(motion.div)`
  max-width: 335px;
  width: 100%;
`;

const FeatureSectionContainer = styled(SectionWrapper)`
  padding: 160px 144px 0 144px;
  background: linear-gradient(180deg, #ffffff 0%, #d0ebff 100%);
  width: 100%;

  @media (max-width: 1224px) {
    padding: 60px 20px 0 20px;
  }
`;

const FeatureSectionContentWrapper = styled(SectionContentWrapper)`
  align-items: flex-start;
  max-width: 1152px;

  @media (max-width: 1224px) {
    max-width: 335px;
  }
`;

const FeatureTitleWrapper = styled(motion.div)``;

const FeatureSectionTitle = styled(SectionTitleText)`
  text-align: left;
`;

const FeatureContainer = styled.div`
  margin-top: 120px;
  display: flex;
  justify-content: space-between;
  gap: 60px;
  padding-bottom: 8px;
  width: 100%;
  @media (max-width: 1224px) {
    margin-top: 75px;
  }
`;

const FeatureListContainer = styled.div`
  min-width: 400px;
  width: 70%;
`;

const FeatureItemContainer = styled.div`
  position: relative;
  height: 551px;
  @media (max-width: 1224px) {
    height: 385px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-width: 330px;
  }
`;

const LineElement = styled.div`
  position: absolute;
  margin-top: 15px;
  height: 551px;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (max-width: 1224px) {
    height: 385px;
  }
`;

const Dot = styled.div`
  width: 14px;
  height: 14px;
  border: 2px solid var(--blue8);
  border-radius: 100%;
  @media (max-width: 1224px) {
    width: 10px;
    height: 10px;
  }
`;

const Line = styled.div`
  opacity: 0.3;
  border-left: 2px solid var(--blue6);
  width: 0;
  height: calc(100% - 30px);
  margin-top: 8px;

  @media (max-width: 1224px) {
    height: calc(100% - 25px);
  }
`;

const FeatureListItem = styled(motion.div)`
  margin-left: 38px;
`;

const FeatureTitle = styled.h3`
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  color: var(--gray17);
  margin: 0;

  @media (max-width: 1224px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

const FeatureDescription = styled.span`
  display: inline-block;
  margin-top: 16px;
  font-size: 17px;
  line-height: 28px;
  color: var(--gray9);

  @media (max-width: 1224px) {
    font-size: 15px;
    line-height: 23px;
    margin-top: 4px;
  }
`;

const ProductDemoContainer = styled(motion.div)`
  position: sticky;
  top: 28vh;
  height: 270px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 100%;
  height: 100%;
  max-width: 708px;
  max-height: 417px;
  margin-bottom: 150px;

  @media (max-width: 1224px) {
    position: initial;
    max-width: 286px;
    margin-bottom: 0;
    margin-left: 40px;
    height: 46%;
  }
`;

const FeatureBackgroundElement = styled(BackgroundElement)`
  position: absolute;
  top: -300px;
  right: -250px;
  z-index: -1;
`;

const ProductDemo = styled.div`
  border-radius: 20px;
  overflow: hidden;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 0px;
  padding-top: 56.25%;

  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -khtml-border-radius: 20px;
  overflow: hidden;
  isolation: isolate;

  @media (max-width: 1224px) {
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -khtml-border-radius: 8px;
  }
`;

const LottieContainer = styled.div`
  position: absolute;
  top: calc(50% - 40px);
`;

const ProductFeatureVideo = styled(motion.video)<{ isVideoVisible: boolean; isVideoChanged: boolean }>`
  visibility: ${({ isVideoVisible }) => (isVideoVisible ? "visible" : "hidden")};
  opacity: ${({ isVideoChanged }) => (isVideoChanged ? "0" : "1")};
  transition: opacity 500ms ease-in, visibility 500ms ease;
  width: 107%;
  height: 107%;
  position: absolute;
  top: -3%;
  left: -3.5%;
`;

const SharedNoteSectionContainer = styled(SectionWrapper)`
  padding: 160px 0 70px 0;
  position: relative;
  overflow: hidden;
  @media (max-width: 1224px) {
    padding: 80px 20px 70px 20px;
  }
`;

const CenteredSectionTitleGradientText = styled(SectionTitleGradientText)`
  text-align: center;
`;

const SharedNoteBackgroundElement = styled(BackgroundElement)`
  top: 69px;
  left: -349px;
`;

const SharedNoteContainer = styled(motion.div)`
  overflow: hidden;
  box-sizing: border-box;
  position: relative;

  width: 1795px;
  height: 400px;
  padding: 30px 0 450px 0px;
  margin-top: 120px;

  &:hover div {
    animation-play-state: paused;
  }
  @media (max-width: 1224px) {
    margin: 60px 0 0;
    overflow-x: scroll;
    ::-webkit-scrollbar {
      -webkit-appearance: none !important;
      display: none !important;
    }
  }
`;

const SharedNoteInnerContainer = styled.div`
  display: inline-block;
  width: 3590px;
  position: absolute;

  animation-name: marquee;
  animation-duration: 80s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  &:nth-of-type(1) {
    margin-left: -250px;
  }

  &:nth-of-type(2) {
    top: 158px;
    margin-left: -150px;
  }
  &:nth-of-type(3) {
    top: 286px;
  }

  @keyframes marquee {
    0% {
      left: 0;
    }
    100% {
      left: 100%;
    }
  }
`;

const SharedNotesAnimationSpan = styled.span`
  float: left;
  width: 50%;
`;

const SharedNoteCard = styled.div`
  transition: all 0.2s ease-out;
  float: left;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  min-width: 335px;
  width: 335px;
  height: 108px;
  margin-right: 24px;

  background: var(--white);
  box-shadow: var(--boxShadow2);
  border-radius: 8px;

  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
`;

const NoteImageContainer = styled.div`
  width: 76px;
  height: 76px;
`;

const NoteImage = styled.img`
  min-width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
`;

const NoteInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const NoteTitleText = styled.span`
  font-weight: 700;
  font-size: 15px;
  line-height: 23px;
  color: var(--gray17);
  margin-top: 8px;
`;

const GradientElem = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 200px;
  background: linear-gradient(90deg, #fff 0%, rgba(255, 255, 255, 0) 100%);

  &:nth-of-type(2) {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
    left: unset;
    right: 0;
  }

  @media (max-width: 1224px) {
    width: 45px;
  }
`;

const TestimonialSectionContainer = styled(SectionWrapper)`
  padding: 120px 0 60px 0;
  overflow: hidden;

  @media (max-width: 1224px) {
    padding: 20px 20px;
  }
`;

const TestimonialContainer = styled(motion.div)`
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  padding: 60px 0;
  width: 1932px;
  height: 360px;

  @media (max-width: 1224px) {
    overflow-x: scroll;
    ::-webkit-scrollbar {
      -webkit-appearance: none !important;
      display: none !important;
    }
  }
`;

const LinkWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
`;

const TestimonialInnerContainer = styled.div`
  display: block;
  width: 3864px;

  position: absolute;
  animation-name: marquee;
  animation-duration: 80s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  &:hover {
    animation-play-state: paused;
  }
  @keyframes marquee {
    0% {
      left: 0;
    }
    100% {
      left: -100%;
    }
  }
`;

const TestimonialAnimationSpan = styled.span`
  float: left;
  width: 50%;
`;

const TestimonialCard = styled.div`
  font-family: "SlidTextStyle", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
  transition: all 0.2s ease-out;
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px 24px 24px 24px;
  margin-right: 24px;
  gap: 24px;

  min-width: 298px;
  width: 298px;

  background:var(--white);
  border: 1px solid var(--gray2);

  box-shadow: var(--boxShadow2);
  border-radius: 8px;

  &:hover {
    transform: scale(1.1);
  }
`;

const TestimonialContent = styled.p`
  margin: 0;
  font-size: 15px;
  line-height: 23px;
  color: var(--gray17);
`;

const TestimonialWriterInfo = styled.div`
  display: flex;
  align-items: center;
  line-height: 0;
`;

const WriterAvatar = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background-color: var(--gray2);
`;

const WriterNameWrapper = styled.div`
  margin-left: 16px;
`;

const WriterName = styled.span`
  font-size: 15px;
  line-height: 20px;
  color: var(--gray13);
`;

const ScoreWrapper = styled.div`
  line-height: 0;
  left: -2px;
  position: relative;
`;

const EndingSectionContainer = styled(SectionWrapper)`
  padding: 260px 0 220px;

  @media (max-width: 1224px) {
    padding: 160px 0 220px;
  }
`;

const EndingSectionTitleGradientText = styled(SectionTitleGradientText)`
  text-align: center;
`;

const ButtonWrapper = styled(motion.div)`
  display: flex;
  gap: 16px;
`;

export default Home;
